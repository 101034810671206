import { Tabs } from 'antd';
import React, { Component } from 'react';
import ContentWrapper from '~components/ContentWrapper';
import Rss from '~components/Rss';
import { SettingInputLimitTitlesDate } from '~components/SettingInputLimitTitlesDate';

import SettingsNavigation from '~components/SettingsNavigation';
import { Advertising } from '~containers';
import s from './styles.scss';
import { observer } from 'mobx-react';
import { useUserStore } from '~hooks';

const PAGE_TITLE = 'Общие настройки';
const { TabPane } = Tabs;

const SettingsCategoriesSectionsPage = observer(() => {
  const { checkPermissions } = useUserStore();

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        {checkPermissions('global.view') && <SettingInputLimitTitlesDate />}
        <div className={s.title}>Выберите раздел</div>
        <Tabs type="card">
          {checkPermissions('global.view') && (
            <TabPane tab="Реклама" key="1">
              <Advertising />
            </TabPane>
          )}
          {checkPermissions('global.view') && (
            <TabPane tab="RSS" key="2">
              <Rss />
            </TabPane>
          )}
        </Tabs>
      </ContentWrapper>
    </>
  );
});

export default SettingsCategoriesSectionsPage;
